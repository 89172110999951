import React from "react";
import { motion } from "framer-motion";
function MyWorksPage() {
  return (
    <div className="bg-stone-200 min-h-screen">
      <div className="h-screen flex justify-center items-center">
        <motion.h3
          className="text-umairah-rose text-9xl"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 1,
            duration: 0.7,
            ease: "easeOut",
          }}
        >
          <span className="veryVogueDisplay">My</span>{" "}
          <span className="veryVogueDisplayItalic">Works</span>
        </motion.h3>
      </div>
    </div>
  );
}

export default MyWorksPage;
