import React, { useEffect, useRef } from "react";
import { motion, useInView, useMotionValue, useTransform } from "framer-motion";

function AnimatedOne({ smoothScroll }) {
  //////////////////////////////////////////////////////
  // ANIMATED ONE SECTION ANIMATION CONFIG START HERE //
  //////////////////////////////////////////////////////
  const sectionOneRef = useRef(null);

  const start = 0.3;
  const end = 0.45;
  const inView = useInView(sectionOneRef);

  const opacity = useTransform(smoothScroll, [start, end], [1, 0]);
  const zoomOut = useTransform(smoothScroll, [start, end], [1, 0.5]);

  // CONTINUES MOVING
  const movingSlowly = useMotionValue(0);
  const movingSlowlyNegative = useMotionValue(0);
  const movingFast = useMotionValue(0);
  const movingBack = useMotionValue(0);

  // MOVING
  const movingRight = useTransform(smoothScroll, [start, 1], [0, 1000]);
  const movingLeft = useTransform(smoothScroll, [start, 1], [-1200, 0]);
  const movingRightTwo = useTransform(smoothScroll, [start, 1], [-2000, -1000]);

  // COMBINE
  const xone = useTransform(() => movingRight.get() + movingSlowly.get());
  const xtwo = useTransform(
    () => movingLeft.get() + movingSlowlyNegative.get()
  );
  const xthree = useTransform(() => movingRightTwo.get() + movingSlowly.get());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const ms = movingSlowly.get() + 0.15;
      movingSlowly.set(ms);

      const msn = movingSlowlyNegative.get() - 0.15;
      movingSlowlyNegative.set(msn);

      const mf = movingFast.get() - 0.7;
      movingFast.set(mf);

      const mb = movingBack.get() + 0.7;
      movingBack.set(mb);
    }, 10);

    // CLEARKAN INTERVAL
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // RESET BALIK
    if (inView) {
      movingSlowly.set(0);
      movingFast.set(0);
      movingBack.set(0);
    }
  }, [inView]);
  ////////////////////////////////////////////////////
  // ANIMATED ONE SECTION ANIMATION CONFIG END HERE //
  ////////////////////////////////////////////////////

  return (
    <motion.div
      style={{ opacity }}
      className="z-10 h-[100vh] bg-gradient-to-b from-transparent to-umairah-dark-blue sticky inset-0 flex flex-col justify-center items-center"
    >
      <div className="absolute z-20 inset-0 flex items-center justify-center">
        <motion.div
          style={{
            width: "800px",
            height: "500px",
            objectFit: "contain",
            // y: movingDown,
            scale: zoomOut,
            opacity,
          }}
          className="overflow-hidden"
        >
          <motion.img
            whileHover={{ scale: 1.1 }}
            transition={{
              damping: 1,
            }}
            alt="portfolio-image"
            src="https://images.unsplash.com/photo-1532517308734-0565178471d2?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
        </motion.div>
      </div>
      <div className="flex flex-col justify-between h-[870px] pb-10">
        <motion.h3
          style={{
            x: xone,
            fontSize: 250,
            fontFamily: "VeryVogueDisplay",
          }}
          className="leading-none font-serif text-nowrap"
        >
          {focus.map((item, index) => (
            <span
              style={{
                fontFamily: item.highlight
                  ? "VeryVogueDisplayItalic"
                  : "VeryVogueDisplay",
              }}
              key={index}
              className={`${
                item.highlight ? "text-stone-100" : "text-umairah-vista-blue"
              }`}
            >
              {item.text}
            </span>
          ))}
        </motion.h3>
        <motion.h3
          style={{ x: xtwo, fontSize: 250, fontWeight: 400 }}
          className="leading-none  font-serif text-nowrap"
        >
          {focus.map((item, index) => (
            <span
              style={{
                fontFamily: item.highlight
                  ? "VeryVogueDisplayItalic"
                  : "VeryVogueDisplay",
              }}
              key={index}
              className={`${
                item.highlight ? "text-stone-100" : "text-umairah-vista-blue"
              }`}
            >
              {item.text}
            </span>
          ))}
        </motion.h3>
        <motion.h3
          style={{ x: xthree, fontSize: 250, fontWeight: 400 }}
          className="leading-none font-serif text-nowrap"
        >
          {focus.map((item, index) => (
            <span
              style={{
                fontFamily: item.highlight
                  ? "VeryVogueDisplayItalic"
                  : "VeryVogueDisplay",
              }}
              key={index}
              className={`${
                item.highlight ? "text-stone-100" : "text-umairah-vista-blue"
              }`}
            >
              {item.text}
            </span>
          ))}
        </motion.h3>
      </div>
    </motion.div>
  );
}

// DATA
const focus = [
  { id: 7, highlight: false, text: " something" },
  { id: 8, highlight: true, text: " lovely." },
  { id: 1, highlight: false, text: " Let's" },
  { id: 2, highlight: true, text: " transform" },
  { id: 3, highlight: false, text: " your" },
  { id: 4, highlight: false, text: " creative" },
  { id: 5, highlight: false, text: " energy" },
  { id: 6, highlight: true, text: " into" },
];

export default AnimatedOne;
