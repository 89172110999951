import React, { useRef } from "react";
import { useScroll, useSpring, useTransform } from "framer-motion";

import AnimatedFive from "../components/AnimatedFive";
import AnimatedOne from "../components/AnimatedOne";
import AnimatedThree from "../components/AnimatedThree";
import AnimatedTwo from "../components/AnimatedTwo";
import AnimatedZero from "../components/AnimatedZero";
import Footer from "../components/Footer";
import { ProgressBarHorizontal } from "../components/ProgressBar";
import { motion } from "framer-motion";

function HomePage() {
  const ref = useRef(null);
  const sectionOneRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });

  // useTransform HOOKS
  const scrollIndicator = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  // BAGI LEMBUT SKIT GERAKAN SCROLL
  const smoothScroll = useSpring(scrollYProgress, { mass: 0.2 }); // <-- BAGI DIA LEMBUT SKET, JGN KERAS SANGAT

  return (
    <div
      ref={ref}
      className="bg-stone-100 w-full relative overflow-hidden"
    >
      <ProgressBarHorizontal scrollIndicator={scrollIndicator} />
      <AnimatedZero smoothScroll={smoothScroll} />
      <AnimatedOne
        sectionOneRef={sectionOneRef}
        smoothScroll={smoothScroll}
      />
      <AnimatedTwo />
      <AnimatedThree sectionOneRef={sectionOneRef} />
      <AnimatedFive smoothScroll={smoothScroll} />
      <Footer />
      <motion.div
        initial={{ bottom: `-3000px` }}
        exit={{ bottom: "0px" }}
        className="h-[3000px] shadow-2xl bg-stone-200 absolute left-0 right-0 z-50 rounded-t-3xl "
        transition={{
          duration: 1,
          ease: "easeOut",
        }}
      />
    </div>
  );
}

export default HomePage;
