import { CgArrowTopRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import React from "react";

export const Button = ({ label, className }) => {
  return (
    <Link
      type="button"
      className={`${className}`}
    >
      <span className="text-9xl">{label}</span>
    </Link>
  );
};

export const BigButton = ({ label, className }) => {
  return (
    <Link
      type="button"
      className={`${className} flex items-center gap-4`}
    >
      <span
        style={{ borderBottomWidth: 4 }}
        className="text-7xl border-b border-stone-100"
      >
        {label}
      </span>
      <div
        style={{ borderWidth: 4 }}
        className="p-2 rounded-full border border-stone-100"
      >
        <CgArrowTopRight size={40} />
      </div>
    </Link>
  );
};
