import { Route, Routes, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import HomePage from "./pages/HomePage";
import MyWorksPage from "./pages/MyWorksPage";
import PortfolioOnePage from "./pages/PortfolioOnePage";
import PortfolioTwoPage from "./pages/PortfolioTwoPage";
import React from "react";

function App() {
  const location = useLocation();
  const locationArr = location.pathname.split("/") ?? [];

  return (
    <AnimatePresence initial={true}>
      <Routes
        location={location}
        key={locationArr[1]}
      >
        <Route
          path="/"
          element={<HomePage />}
        />
        <Route
          path="/my-works"
          element={<MyWorksPage />}
        />
        <Route
          path="/portfolio-one"
          element={<PortfolioOnePage />}
        />
        <Route
          path="/portfolio-two"
          element={<PortfolioTwoPage />}
        />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
