import React, { useEffect, useRef } from "react";
import { motion, useTransform } from "framer-motion";

import { CgArrowTopRight } from "react-icons/cg";

function AnimatedFour({ smoothScroll }) {
  const offset = useTransform(smoothScroll, [0.6, 1], [-400, 0]);

  const texts = useRef(null);

  useEffect(() => {
    offset.on("change", (value) =>
      texts.current.setAttribute("startOffset", `${value}%`)
    );
  }, [offset]);

  return (
    <div className="mt-40 p-10 w-full relative flex justify-center items-center">
      <div className="absolute inset-0 bg-gradient-to-r from-stone-100 via-transparent to-stone-100 from-15% to-85%" />
      <svg
        overflow="visible"
        viewBox="0 0 1280 500"
        xmlns="http://www.w3.org/2000/svg"
        height={500}
      >
        <path
          id="curve-spline"
          fill="none"
          d="M-133 245C-8.197 245 2.761 1 164.7 1s154.634 223.026 345.186 223.026C699.221 224.026 678.522 1 847.767 1c169.243 0 183.243 244 352.493 244C1369.5 245 1314.1 1 1487 1"
        />
        <text className="veryVogueDisplay text-umairah-dark-blue text-5xl tracking-wider">
          <textPath
            ref={(ref) => {
              console.log(ref);
              texts.current = ref;
            }}
            style={{
              fill: "#7C90C9",
            }}
            href="#curve-spline"
          >
            {`Let’s transform your creative energy into something lovely.Let’s
            transform your creative energy into something lovely . Let’s transform
            your creative energy into something lovely . Let’s transform your
            creative energy into something lovely . Let’s transform your creative
            energy into something lovely . `}
          </textPath>
        </text>
      </svg>
    </div>
  );
}

function AnimatedFive({ smoothScroll }) {
  const degreeRaw = useTransform(smoothScroll, [0.65, 1], [0, -130]);
  const opacityMainBox = useTransform(smoothScroll, [0.5, 0.75], [0, 1]);

  const additionalRadius = 500;
  const hypotenuse = (1440 + additionalRadius) / 2;
  const gapDegree = 20; // <-- ITEMS GAP THE HIGHER THE NUMBER, THE CLOSER THE ITEMS
  const cardSize = 300;

  const topOne = useTransform(
    () => Math.sin(degreeRaw.get() / gapDegree) * hypotenuse - cardSize / 2
  );

  const leftOne = useTransform(
    () => Math.cos(degreeRaw.get() / gapDegree) * hypotenuse - cardSize / 2
  );

  const topTwo = useTransform(
    () =>
      Math.sin((degreeRaw.get() + 10) / gapDegree) * hypotenuse - cardSize / 2
  );
  const leftTwo = useTransform(
    () =>
      Math.cos((degreeRaw.get() + 10) / gapDegree) * hypotenuse - cardSize / 2
  );

  const topThree = useTransform(
    () =>
      Math.sin((degreeRaw.get() + 20) / gapDegree) * hypotenuse - cardSize / 2
  );
  const leftThree = useTransform(
    () =>
      Math.cos((degreeRaw.get() + 20) / gapDegree) * hypotenuse - cardSize / 2
  );

  const topFour = useTransform(
    () =>
      Math.sin((degreeRaw.get() + 30) / gapDegree) * hypotenuse - cardSize / 2
  );
  const leftFour = useTransform(
    () =>
      Math.cos((degreeRaw.get() + 30) / gapDegree) * hypotenuse - cardSize / 2
  );

  // OPACITIES
  const opacityOneRaw = useTransform(() => Math.abs(leftOne.get() + 150));
  const opacityTwoRaw = useTransform(() => Math.abs(leftTwo.get() + 150));
  const opacityThreeRaw = useTransform(() => Math.abs(leftThree.get() + 150));
  const opacityFourRaw = useTransform(() => Math.abs(leftFour.get() + 150));

  // CONVERT OPACITIES RATIOS
  const opacityOne = useTransform(opacityOneRaw, [650, 300], [0, 1]);
  const opacityTwo = useTransform(opacityTwoRaw, [650, 300], [0, 1]);
  const opacityThree = useTransform(opacityThreeRaw, [650, 300], [0, 1]);
  const opacityFour = useTransform(opacityFourRaw, [650, 300], [0, 1]);

  useEffect(() => {
    leftOne.on("change", (value) =>
      console.log("APA LEFT", Math.abs(value + 150))
    );
  }, []);

  return (
    <div className="mt-10">
      <div className="flex justify-center items-center">
        <motion.h3
          className="text-umairah-rose text-9xl"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 1,
            duration: 0.7,
            ease: "easeOut",
          }}
        >
          <span className="veryVogueDisplay">My</span>{" "}
          <span className="veryVogueDisplayItalic">Expertise</span>
        </motion.h3>
      </div>
      <motion.div
        style={{ opacity: opacityMainBox }}
        className="h-[600px] w-screen relative"
      >
        {/* LEPAS TUKAR ADDITIONAL RADIUS TU, TUKAR PULAK TOP DEKAT BAWAH NI  */}
        <div className="absolute top-[1200px] h-[50%] w-1/2 bottom-0 right-0">
          <motion.div
            style={{
              top: topOne,
              left: leftOne,
              opacity: opacityOne,
              height: cardSize,
              width: cardSize,
            }}
            className="absolute bg-stone-900 rounded-full"
          >
            <div className="p-3 relative w-full h-full  flex justify-center items-center">
              <p className="text-4xl tracking-wide text-center">
                Branding & Logo Design
              </p>
              <div className="absolute -bottom-6 p-3 bg-stone-100 rounded-full border-4 border-stone-900">
                <CgArrowTopRight
                  size={40}
                  className="text-stone-900"
                />
              </div>
            </div>
          </motion.div>
          <motion.div
            style={{
              top: topTwo,
              left: leftTwo,
              opacity: opacityTwo,
              height: cardSize,
              width: cardSize,
            }}
            className="absolute bg-stone-900 rounded-full flex justify-center items-center"
          >
            <div className="p-3 relative w-full h-full  flex justify-center items-center">
              <p className="text-4xl tracking-wide text-center">
                Packaging Design
              </p>
              <div className="absolute -bottom-6 p-3 bg-stone-100 rounded-full border-4 border-stone-900">
                <CgArrowTopRight
                  size={40}
                  className="text-stone-900"
                />
              </div>
            </div>
          </motion.div>
          <motion.div
            style={{
              top: topThree,
              left: leftThree,
              opacity: opacityThree,
              height: cardSize,
              width: cardSize,
            }}
            className="absolute bg-stone-900 rounded-full flex justify-center items-center"
          >
            <div className="p-3 relative w-full h-full  flex justify-center items-center">
              <p className="text-4xl tracking-wide text-center">
                Editorial Design
              </p>
              <div className="absolute -bottom-6 p-3 bg-stone-100 rounded-full border-4 border-stone-900">
                <CgArrowTopRight
                  size={40}
                  className="text-stone-900"
                />
              </div>
            </div>
          </motion.div>
          <motion.div
            style={{
              top: topFour,
              left: leftFour,
              opacity: opacityFour,
              height: cardSize,
              width: cardSize,
            }}
            className="absolute bg-stone-900 rounded-full flex justify-center items-center"
          >
            <div className="p-3 relative w-full h-full  flex justify-center items-center">
              <p className="text-4xl tracking-wide text-center">
                Social Media Contents
              </p>
              <div className="absolute -bottom-6 p-3 bg-stone-100 rounded-full border-4 border-stone-900">
                <CgArrowTopRight
                  size={40}
                  className="text-stone-900"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <AnimatedFour smoothScroll={smoothScroll} />
    </div>
  );
}

export default AnimatedFive;
