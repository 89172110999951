export const portfolios = [
  {
    id: 1,
    pathname: "/portfolio-one",
    urlImage:
      "https://plus.unsplash.com/premium_photo-1679079455735-723926fdf229?q=80&w=2576&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "portfolio-one",
    intialStyle: {
      width: "300px",
      height: "470px",
    },
    className: "object-cover absolute left-[10px] top-[120px]",
  },
  {
    id: 2,
    pathname: "/portfolio-two",
    urlImage:
      "https://images.unsplash.com/photo-1713208176490-3ac46ac160a8?q=80&w=2535&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "portfolio-two",
    intialStyle: {
      width: "300px",
      height: "470px",
    },
    className: "object-cover absolute left-[340px] top-[80px]",
  },
  {
    id: 3,
    pathname: "/portfolio-three",
    urlImage:
      "https://images.unsplash.com/photo-1713268527421-44e7ce8b91d4?q=80&w=2535&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "portfolio-three",
    intialStyle: {
      width: "300px",
      height: "470px",
    },
    className: "object-cover absolute right-[320px] top-[106px]",
  },
  {
    id: 4,
    pathname: "/portfolio-four",
    urlImage:
      "https://images.unsplash.com/photo-1713539794386-d54441a60381?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "portfolio-four",
    intialStyle: {
      width: "300px",
      height: "470px",
    },
    className: "object-cover absolute right-[10px] top-[70px]",
  },
];
