import { CgArrowTopRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import React from "react";
const projects = [
  {
    id: 1,
    name: "Maison",
    category: "Packaging Design",
    thumbnail:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/2da910153532663.Y3JvcCw0NzU5LDM3MjIsMCww.png",
  },
  {
    id: 1,
    name: "Tomato",
    category: "Packaging Design",
    thumbnail:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/69b22c169953507.Y3JvcCwxMDA3LDc4OCwxOTcsMA.png",
  },
  {
    id: 1,
    name: "Le Saigon",
    category: "Packaging Design",
    thumbnail:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/704b00153851949.Y3JvcCw0NzU5LDM3MjIsMCww.png",
  },
  {
    id: 1,
    name: "Chocolate Sarawak",
    category: "Packaging Design",
    thumbnail:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/47395e153189797.Y3JvcCwzMTcyLDI0ODEsMCww.png",
  },
  {
    id: 1,
    name: "Portfolio",
    category: "Packaging Design",
    thumbnail:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/b8703c164122957.Y3JvcCwzMTcwLDI0ODAsMTY0LDA.jpg",
  },
  {
    id: 1,
    name: "Wound Rescue",
    category: "Packaging Design",
    thumbnail:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/cb3944153345791.Y3JvcCwzODg0LDMwMzgsMTE3Myw1NDg.jpg",
  },
];
function AnimatedThree({ sectionOneRef }) {
  return (
    <div
      ref={sectionOneRef}
      className="py-32"
    >
      <div className="h-full container mx-auto flex flex-col justify-center items-center gap-16">
        <h3
          style={{ fontSize: 130 }}
          className=" leading-none text-umairah-rose tracking-wide"
        >
          <span className="veryVogueDisplay">Featured</span>{" "}
          <span className="veryVogueDisplayItalic">Projects</span>
        </h3>

        <div className="max-w-[700px]">
          <p
            className="leading-tight text-stone-900 oswaldLight text-center"
            style={{ fontSize: 33 }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>

      <div className="mt-40">
        <div className="container mx-auto relative  flex flex-wrap gap-20 justify-evenly">
          {projects.map((proj, index) => (
            <div
              key={index}
              className={`w-[480px] h-[480px] object-cover flex ${
                index % 2 !== 0 ? "items-start" : "items-end"
              }`}
            >
              <div className={``}>
                <p className="veryVogueDisplayItalic text-stone-900">
                  {proj.category}
                </p>
                <p className="oswaldMedium text-stone-900 text-5xl truncate">
                  {proj.name}
                </p>
                <img
                  className="mt-5 w-[480px] h-[320px]"
                  alt="project-one"
                  src={proj.thumbnail}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-32">
        <Link
          className="text-stone-900"
          to="/my-works"
        >
          <div className="flex items-center justify-center gap-6">
            <h3 className="text-stone-900 underline text-8xl tracking-wide leading-none">
              View All <span className="veryVogueDisplayItalic">Works</span>
            </h3>
            <div
              style={{ borderWidth: 5 }}
              className="rounded-full p-3"
            >
              <CgArrowTopRight className="text-7xl" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AnimatedThree;
