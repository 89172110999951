import React from "react";
import { motion } from "framer-motion";
import { portfolios } from "../utils/datas";

function PortfolioTwoPage() {
  const data = portfolios.find((item) => item.name === "portfolio-two");

  return (
    <div className="h-screen relative">
      <motion.img
        style={data.intialStyle}
        animate={{
          height: "50vh",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
        }}
        transition={{
          duration: 2,
          delay: 1,
          ease: "easeIn",
        }}
        className={data.className}
        alt={data.name}
        src={data.urlImage}
      />
    </div>
  );
}

export default PortfolioTwoPage;
