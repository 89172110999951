import React from "react";
import { motion } from "framer-motion";

export const ProgressBarHorizontal = ({ scrollIndicator }) => {
  return (
    <motion.div
      className="z-20 origin-left h-2 bg-red-500 fixed top-0 right-0 left-0"
      style={{ width: scrollIndicator }}
    />
  );
};

export const ProgressBarVertical = ({ scrollIndicator }) => {
  return (
    <motion.div
      className="z-20 origin-left h-2 bg-red-500 fixed top-0 right-0 left-0"
      style={{ height: scrollIndicator }}
    />
  );
};
