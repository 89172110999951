import { BigButton } from "./Button";
import { CgChevronUp } from "react-icons/cg";
import React from "react";

function Footer() {
  return (
    <div className="w-full bg-gradient-to-b from-umairah-darker-vista to-umairah-vista-blue">
      <div className="py-5 bg-stone-100 rounded-b-full flex justify-center items-center">
        <div className="flex items-center flex-col">
          <p className="text-stone-900 text-6xl">Back To Top</p>
          <div className="mt-5 p-3 border-4 border-stone-900 rounded-full">
            <CgChevronUp
              className="text-stone-900"
              size={50}
            />
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <h3
          style={{ fontSize: 280 }}
          className="leading-none text-center text-wrap"
        >
          <span className="text-umairah-vista-blue">Let's</span>{" "}
          <span className="text-stone-100 veryVogueDisplayItalic">Connect</span>
        </h3>

        <div className="flex justify-evenly w-full mt-16 flex-wrap ">
          <BigButton
            className="md:mb-0 mb-10"
            label="Email"
            to=""
          />
          <BigButton
            className="md:mb-0 mb-10"
            label="Linkedin"
            to=""
          />
          <BigButton
            className="md:mb-0 mb-10"
            label="Behance"
            to=""
          />
        </div>

        <div className="flex flex-wrap items-center justify-evenly py-10 mt-16">
          <p className="text-stone-100 text-3xl md:mb-0 mb-10">
            <span className="text-umairah-rose veryVogueDisplayItalic">
              Designed By
            </span>{" "}
            <span>Umairah Razak</span>
          </p>
          <p className="text-stone-100 text-3xl md:mb-0 mb-10">
            <span className="text-umairah-rose veryVogueDisplayItalic">
              Developed By
            </span>{" "}
            <span>ahmadhazim.com</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
