import React, { useRef } from "react";
import { easeOut, motion, useTransform } from "framer-motion";

import { UmairahLogo } from "../assets/svgs";

function AnimatedZero({ smoothScroll }) {
  const ref = useRef(null);

  const y = useTransform(smoothScroll, [0, 0.8], [0, 200]);
  const opacity = useTransform(smoothScroll, [0, 0.08], [1, 0], {
    ease: easeOut,
  });
  const scale = useTransform(smoothScroll, [0, 0.1], [1, 0.5]);

  const subtitle = "GRAPHIC DESIGNER & DTP ARTIST";

  const defaultAnimation = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <div
      ref={ref}
      className="z-20 h-screen sticky inset-0 flex flex-col justify-center py-60"
    >
      <div className="flex flex-col justify-center items-center gap-10">
        <motion.div style={{ y, opacity, scale }}>
          <UmairahLogo />
        </motion.div>
        <motion.div style={{ y, opacity, scale, letterSpacing: 7 }}>
          <motion.span
            initial="initial"
            whileInView="animate"
            exit="exit"
            transition={{
              staggerChildren: 1,
            }}
            className="text-stone-600 text-3xl"
          >
            {subtitle.split("").map((char, index) => (
              <motion.span
                variants={defaultAnimation}
                key={index}
                className={`${
                  char === "&" ? "veryVogueDisplay" : "oswaldRegular"
                }`}
                transition={{ delay: 1, duration: 2 }}
              >
                {char}
              </motion.span>
            ))}
          </motion.span>
        </motion.div>
      </div>
    </div>
  );
}

export default AnimatedZero;
