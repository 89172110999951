import React from "react";
import { motion } from "framer-motion";
import { portfolios } from "../utils/datas";

function PortfolioOnePage() {
  const data = portfolios.find((item) => item.name === "portfolio-one");

  return (
    <div className="bg-stone-200">
      <div className="h-screen relative">portfolio one</div>
    </div>
  );
}

export default PortfolioOnePage;
