import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

function AnimatedTwo({ smoothScroll }) {
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });

  const variants = {
    initial: {
      opacity: 0,
      y: 200,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div
      ref={ref}
      className="z-30 h-screen overflow-hidden "
    >
      <motion.div
        variants={variants}
        initial="initial"
        animate={inView ? "animate" : "initial"}
        transition={{
          delay: 1,
          duration: 2,
        }}
        className="container mx-auto flex justify-center items-center h-full"
      >
        <p
          className="leading-tight text-justify"
          style={{ fontSize: 48, paddingLeft: 40, paddingRight: 40 }}
        >
          <span className="oswaldRegular text-umairah-darker-blue">
            A graphic designer and DTP artist,
          </span>
          <span className="oswaldLight">
            {" "}
            with more than 6 years experience in desktop publishing, primarily
            in advertising, also a 1+ year in brand management.
          </span>
        </p>
      </motion.div>
    </div>
  );
}

export default AnimatedTwo;
